@import 'conf/conf';

$left-space: $sidebar-width;

@include scrollbars(.5em, #d9d9d9, rgba(0, 0, 0, 0));

html {
    position: relative;
    min-width: 320px;
}

html,
body {
    min-height: 100%;
    height: 100%;
    min-width: $resMin;
}

main {
    min-height: 100%;
    position: relative;
    font: 14px/16px $font-family;
    color: $default-text;

    @include body-bg();

    .additional-bg {
        display: none;
        @include additional-bg();
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    html {
        overflow: hidden;
        height: 100%;
    }

    body {
        overflow: auto;
        height: 100%;
    }
}

a {
    transition: color 0.5s ease;
    outline: 0 !important;
}

.body-bg {
    display: none;
}

.al-header {
    display: block;
    height: 49px;
    margin: 0;
    background-repeat: repeat-x;
    position: relative;
    z-index: 905;
    color: #444444;
}

.al-main {
    margin-left: $left-space;
    padding: 66px 0 1em 0;
    min-height: 500px;
    position: relative;
    overflow-x: hidden;
    padding-bottom: 80px;
}

.container {
    text-align: center
}

.left {
    float: left;
}

.right {
    float: right;
}

.center {
    display: inline-block
}

.al-footer {
    z-index: 1001;
    background: rgb(0, 90, 142);
    background: linear-gradient(90deg, rgba(0, 90, 142, 1) 0%, rgba(19, 38, 80, 1) 60%, rgba(12, 78, 126, 1) 100%);
    max-height: 82px;
    width: 100%;
    position: absolute;
    display: block;
    bottom: 0;
    font-size: 13px;
    color: $default-text;
    transition: padding-left 0.5s ease;
}

.al-footer-main {
    float: left;
    margin-left: 15px;
}

.al-copy {
    float: left;
}

.al-footer-right {
    float: right;
    margin-right: 12px;

    i {
        margin: 0 4px;
        color: $danger;
        font-size: 12px;
    }

    a {
        margin-left: 4px;
        color: $default-text;

        &:hover {
            color: $danger;
        }
    }
}

.al-share {
    margin: -6px 0 0 12px;
    padding: 0;
    list-style: none;
    float: left;

    li {
        list-style: none;
        float: left;
        margin-left: 16px;

        i {
            cursor: pointer;
            transition: all 0.1s ease;
            color: white;
            padding: 6px;
            box-sizing: content-box;
            font-size: 16px;

            &:hover {
                transform: scale(1.2);
            }
        }

        i.fa-facebook-square {
            color: $facebook-color;
        }

        i.fa-twitter-square {
            color: $twitter-color;
        }

        i.fa-google-plus-square {
            color: $google-color;
        }
    }
}

.al-content {
    padding: 8px 8px 8px 12px;
}

.button {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: $resXS) {
    .al-content {
        padding: 8px 20px;
    }
}

.vis-hidden {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.icon-up,
.icon-down {
    width: 5px;
    height: 13px;
    display: block;
}

.icon-up {
    //@include bg-nr('arrow-green-up.svg');
    background: url('/assets/img/arrow-green-up.svg') no-repeat 0 0;
}

.icon-down {
    background: url('/assets/img/arrow-red-down.svg') no-repeat 0 0;
}

.disable-text-selection {
    -webkit-touch-callout: none;
    user-select: none;
}

.align-right {
    text-align: right
}

.amcharts-chart-div>a {
    font-size: 6px !important;
}

.content-panel {
    padding-left: 22px;
    padding-top: 26px;
}

@media (max-width: 590px) {
    .al-footer-right {
        float: none;
        margin-bottom: 19px;
        margin-right: 0;
    }

    .al-footer {
        max-height: 100px;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding-left: 0px !important;
    }

    .al-footer span {
        padding: 0.3em;
    }

    .al-main {
        padding-bottom: 76px;
    }

    .al-footer-main {
        float: none;
        display: inline-block;
    }
}

.full-invisible {
    visibility: hidden !important;

    * {
        visibility: hidden !important;
    }
}

.irs-grid-text {
    color: $default-text;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.btn-wide {
    width: 10em;
}

.btn-wait,
.btn-wait-light {
    position: relative;
    padding-right: 48px;
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) 6px;
}

.btn-wide.btn-wait,
.btn-wide.btn-wait-light {
    padding-left: 12px;
}

.btn-wait:after,
.btn-wait:before,
.btn-wait-light:after,
.btn-wait-light:before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
    right: 12px;
    opacity: 1;
}

.btn-wait:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='.7' fill='none' stroke='black' stroke-width='3.8' stroke-dasharray='1 31 1 11 1 40' stroke-linecap='round' /%3E%3C/svg%3E");
    animation: spin 3.1s linear infinite;
}

.btn-wait:after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' fill='none' opacity='.7' stroke='black' stroke-width='3.8' stroke-dasharray='1 6 1 20 1 13' stroke-linecap='round' /%3E%3C/svg%3E");
    animation: spin 2.3s linear infinite;
}


.btn-wait-light:before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='.7' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='1 31 1 11 1 40' stroke-linecap='round' /%3E%3C/svg%3E");
    animation: spin 3.1s linear infinite;
}

.btn-wait-light:after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' fill='none' opacity='.7' stroke='white' stroke-width='3.8' stroke-dasharray='1 6 1 20 1 13' stroke-linecap='round' /%3E%3C/svg%3E");
    animation: spin 2.3s linear infinite;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/02/2021 02:48
  	*/

	@font-face {
	  font-family: "Flaticon";
	  src: url("./Flaticon.eot");
	  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
	    url("./Flaticon.woff2") format("woff2"),
	    url("./Flaticon.woff") format("woff"),
	    url("./Flaticon.ttf") format("truetype"),
	    url("./Flaticon.svg#Flaticon") format("svg");
	  font-weight: normal;
	  font-style: normal;
	}

	@media screen and (-webkit-min-device-pixel-ratio:0) {
	  @font-face {
	    font-family: "Flaticon";
	    src: url("./Flaticon.svg#Flaticon") format("svg");
	  }
	}

	[class^="flaticon-"]:before,
	[class*=" flaticon-"]:before,
	[class^="flaticon-"]:after,
	[class*=" flaticon-"]:after {
	  font-family: Flaticon;
	  font-size: 20px;
	  font-style: normal;
	}

  [class^="big flaticon-"]:before,
	[class*=" big flaticon-"]:before,
	[class^="big flaticon-"]:after,
	[class*=" big flaticon-"]:after {
	  font-family: Flaticon;
	  font-size: 40px;
	  font-style: normal;
    margin-right: 0.2em;
	}

	.flaticon-devaluation:before {
	  content: "\f100";
	}

	.flaticon-profit:before {
	  content: "\f101";
	}

	.flaticon-loan:before {
	  content: "\f102";
	}

	.flaticon-growth:before {
	  content: "\f103";
	}

	.flaticon-salary:before {
	  content: "\f104";
	}

	.flaticon-money:before {
	  content: "\f105";
	}

	.flaticon-salary-1:before {
	  content: "\f106";
	}

	.flaticon-money-1:before {
	  content: "\f107";
	}

	.flaticon-receipt:before {
	  content: "\f108";
	}

	.flaticon-bill:before {
	  content: "\f109";
	}

	.flaticon-bill-1:before {
	  content: "\f10a";
	}

	.flaticon-investor:before {
	  content: "\f10b";
	}

	.flaticon-bank:before {
	  content: "\f10c";
	}

	.flaticon-bank-1:before {
	  content: "\f10d";
	}

	.flaticon-bank-2:before {
	  content: "\f10e";
	}

	.flaticon-bank-3:before {
	  content: "\f10f";
	}

	.flaticon-office-building:before {
	  content: "\f110";
	}

	.flaticon-building:before {
	  content: "\f111";
	}

	.flaticon-statistics:before {
	  content: "\f112";
	}

	.flaticon-report:before {
	  content: "\f113";
	}

	.flaticon-empresa:before {
	  content: "\f114";
	}

	.flaticon-portapapeles:before {
	  content: "\f115";
	}

	.flaticon-buscar:before {
	  content: "\f116";
	}

	.flaticon-configuraciones:before {
	  content: "\f117";
	}

	.flaticon-agency:before {
	  content: "\f118";
	}

	.flaticon-dashboard:before {
	  content: "\f119";
	}

	.flaticon-speedometer:before {
	  content: "\f11a";
	}

	.flaticon-credit-card:before {
	  content: "\f11b";
	}

	.flaticon-cheque:before {
	  content: "\f11c";
	}

	.flaticon-cheque-bancario:before {
	  content: "\f11d";
	}

	.flaticon-accounting:before {
	  content: "\f11e";
	}

	.flaticon-mobile-banking:before {
	  content: "\f11f";
	}

	.flaticon-caja-registradora:before {
	  content: "\f120";
	}

	.flaticon-caja-registradora-1:before {
	  content: "\f121";
	}

	.flaticon-silueta-de-grupo-de-usuarios:before {
	  content: "\f122";
	}

	.flaticon-sitio-web:before {
	  content: "\f123";
	}

	.flaticon-cuenta-bancaria:before {
	  content: "\f124";
	}

	.flaticon-cuenta:before {
	  content: "\f125";
	}

	.flaticon-dinero:before {
	  content: "\f126";
	}

	.flaticon-cheque-1:before {
	  content: "\f127";
	}

	.flaticon-efectivo:before {
	  content: "\f128";
	}

	.flaticon-dinero-1:before {
	  content: "\f129";
	}

	.flaticon-pagar:before {
	  content: "\f12a";
	}

	.flaticon-ingenieros:before {
	  content: "\f12b";
	}

	.flaticon-trabajo-en-equipo:before {
	  content: "\f12c";
	}

	.flaticon-tax:before {
	  content: "\f12d";
	}

	.flaticon-tax-1:before {
	  content: "\f12e";
	}

	.flaticon-tax-2:before {
	  content: "\f12f";
	}

	.flaticon-invoice:before {
	  content: "\f130";
	}

	.flaticon-fax:before {
	  content: "\f131";
	}

	.flaticon-proveedor-hotelero:before {
	  content: "\f132";
	}

	.flaticon-distribucion:before {
	  content: "\f133";
	}

	.flaticon-pequeno-camion:before {
	  content: "\f134";
	}

	.flaticon-envio:before {
	  content: "\f135";
	}

	.flaticon-cliente:before {
	  content: "\f136";
	}

	.flaticon-cliente-1:before {
	  content: "\f137";
	}

	.flaticon-payment-method:before {
	  content: "\f138";
	}
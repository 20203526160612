h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family;
  width: 100%;
  margin-top: 0;
}

h1.color,
h2.color,
h3.color,
h4.color,
h5.color,
h6.color {
  color: $danger;
}

body a {
  color: $activelink;
  text-decoration: none !important;
  transition: color 0.2s ease;

  &:hover {
    color: $hoverlink;
  }
}

h1 {
  font-size: 32px;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

.typography-document-samples {


  p {
    margin: 0;
  }

  .typography-widget {
    height: 100%;

    .card {
      height: 620px;
    }

    .card-title {
      text-align: center;
      width: 100%;
    }

    .card.with-scroll .card-body {
      height: calc(100% - 45px);
    }

    .card-content {
      padding: 15px 22px 5px 22px;
    }

  }

}

.heading-widget {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
    font-weight: $font-light;
    text-align: center;
  }

  p {
    line-height: 16px;
    font-weight: $font-normal;
    text-align: center;
  }
}

.more-text-widget {
  text-align: center;
  font-size: 14px;

  p {
    line-height: 17px;
  }

  .gray {
    color: #767676;
  }

  .black {
    color: #585858;
  }

  .light-text {
    font-weight: $font-light;
  }

  .regular-text {
    font-weight: $font-normal;
  }

  .upper-text {
    text-transform: uppercase;
  }

  .bold-text {
    font-weight: $font-bold;
  }

  .small-text {
    padding: 5px 0 0 0;

    p {
      font-size: 9px;
      font-weight: $font-light;
      line-height: 10px;
    }
  }
}

.color-widget {
  text-align: center;
  font-size: 14px;
  font-weight: $font-normal;

  p {
    line-height: 17px;
  }

  .section-block {
    margin: 14px 0;
  }

  .yellow-text p {
    color: $warning;
  }

  .red-text p {
    color: $danger;
  }

  .links {
    h3 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0;

      &.hovered {
        a {
          color: $hoverlink;
        }
      }
    }
  }

}

.lists-widget {

  font-weight: $font-normal;

  .list-header {
    width: 100%;
    text-align: center;
  }

  .accent {
    margin-top: 30px;
    color: $warning-bg;
    line-height: 14px;
    font-size: 14px;
    padding-left: 11px;
    border-left: 4px solid $warning-bg;
    margin-left: 13px;
  }

  ul.blur,
  ol.blur {
    padding-left: 13px;
    margin-bottom: 19px;
    list-style: none;
    padding-top: 1px;

    li {
      margin-top: 5px;
      font-size: 14px;

      ul,
      ol {
        padding-left: 20px;
        margin-bottom: 0;
        list-style: none;
      }
    }
  }

  ul.blur {
    li {
      &:before {
        content: "• ";
        color: $warning-bg;
        width: 10px;
        display: inline-block;
      }
    }
  }

  ol.blur {
    counter-reset: section;

    li {
      color: $warning-bg;
      padding-left: 0;
      line-height: 14px;
      position: relative;

      span {
        color: $content-text;
        display: block;
      }

      ol {
        padding-left: 0;
        margin-left: 12px;
      }

      &:before {
        content: counters(section, ".") ".";
        counter-increment: section;
        width: 19px;
        position: absolute;
        left: 0;
        top: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &>li {
      span {
        padding-left: 14px;
      }
    }

    ol {
      counter-reset: section;

      &>li {
        &:before {
          width: 30px;
        }

        span {
          padding-left: 27px
        }
      }

      ol {
        &>li {
          &:before {
            width: 40px;
          }

          span {
            padding-left: 40px;
          }
        }
      }
    }
  }
}

.columns-section {
  background-color: $default;
}

p {
  margin-bottom: 12px;
  font-family: $font-family;
  font-size: 14px;
}

p.small-text {
  color: $help-text;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
}

.cols-two {
  margin-bottom: 50px;

  &>div {
    float: left;
    width: 350px;
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.cols-three {
  margin-bottom: 50px;

  &>div {
    float: left;
    width: 222px;
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }
}

a.learn-more {
  font-size: 14px;
  font-weight: $font-bold;
  text-decoration: none;
  line-height: 24px;
}

.img-wrapper {
  margin-bottom: 19px;
  margin-top: 5px;
  overflow: hidden;
  height: 180px;

  img {
    width: 100%;
  }
}

.cols-three p {
  margin-bottom: 10px;
}

.banner {
  position: relative;
  margin-bottom: 20px;
}

.large-banner-wrapper {
  overflow: hidden;
  height: 400px;

  img {
    height: 100%;
    width: 100%;
    display: block
  }
}

.banner-text-wrapper {
  margin-top: -400px;
  height: 400px;
  text-align: center;
}

.banner-text {
  padding: 85px 90px 60px;
  display: inline-block;
  margin: 67px auto;
  background: $default;
  min-width: 432px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.75);

  h1 {
    font-weight: $font-bold;
    width: 100%;
    color: $label-text;
    margin-bottom: 10px;
  }

  p {
    font-size: 24px;
    line-height: 30px;
    font-weight: $font-light;
    color: $primary;
    margin-bottom: 0px;
  }
}

@media (max-width: 600px) {
  .banner-text {
    padding: 55px 60px 30px;
    min-width: 0;

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }
}

@media (max-width: 400px) {
  .banner-text {
    min-width: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.photo-desc {
  margin-top: 12px;
  text-align: center;
}

.text-info {
  width: 90%;

  p {
    margin-bottom: 10px;
  }
}

.section-block {
  padding-bottom: 12px;
}

.separator {
  height: 1px;
  background: $border-light;
  width: 100%;
  margin-bottom: 19px;
  margin-top: 16px;
}

.section {
  padding: 0 20px 50px 20px;
}

.card.banner-column-panel {
  padding: 0;
  margin-bottom: 90px;

  .card-body {
    padding: 0;
  }
}


@media screen and (min-width: 1620px) {

  .col-xlg-1 {
    max-width: 8.33333333%;
    flex: 0 0 8.33333333%;
  }

  .col-xlg-2 {
    max-width: 16.66666667%;
    flex: 0 0 16.66666667%;
  }

  .col-xlg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xlg-4 {
    max-width: 33.33333333%;
    flex: 0 0 33.33333333%;
  }

  .col-xlg-5 {
    max-width: 41.66666667%;
    flex: 0 0 41.66666667%;
  }

  .col-xlg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xlg-7 {
    max-width: 58.33333333%;
    flex: 0 0 58.33333333%;
  }

  .col-xlg-8 {
    max-width: 66.66666667%;
    flex: 0 0 66.66666667%;
  }

  .col-xlg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xlg-10 {
    max-width: 83.33333333%;
    flex: 0 0 83.33333333%;
  }

  .col-xlg-11 {
    max-width: 91.66666667%;
    flex: 0 0 91.66666667%;
  }

  .col-xlg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
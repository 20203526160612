@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.completer-dropdown {
  max-height: 350px !important;
  overflow-y: auto;
  overflow-x: hidden;
  width: 90% !important;
}


.concepto-cell {
  padding-bottom: 1em;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

.concepto-cell label {
  font-weight: bold;
}

.concepto-cell-row {
  flex-direction: row;
  margin-top: auto;
}

.concepto-cell-row label {
  margin-left: 1em;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1em;
}

.concepto-cell-row input {
  width: 18px;
  height: 18px;
}

.alert-shadow-border {
  border: 1px solid #ed7878;
  &:focus {
    box-shadow: none;
    border-color: #ed7878;
  }
  border-left: 5px solid #ed7878;
}

.alert,
.alert-danger {
  font-size: xx-small;
  padding-top: initial;
  padding-bottom: initial;
}



.alert-shadow-border-completer .completer-input {
  border: 1px solid #ed7878;
  &:focus {
    box-shadow: none;
    border-color: #ed7878;
  }
  border-left: 5px solid #ed7878;
}

.success-shadow-border {
  border: 1px solid #a6c733;
  &:focus {
    box-shadow: none;
    border-color: #a6c733;
  }
  border-left: 5px solid #a6c733;
}
.success-shadow-border-completer .completer-input {
  border: 1px solid#a6c733;
  &:focus {
    box-shadow: none;
    border-color: #a6c733;
  }
  border-left: 5px solid #a6c733;
}

.neutral-shadow-border {
  border: 1px solid #2e3e8d;
  &:focus {
    box-shadow: none;
    border-color: #2e3e8d;
  }
}

.neutral-shadow-border-completer .completer-input {
  border: 1px solid #2e3e8d;

  &:focus {
    box-shadow: none;
    border-color: #2e3e8d;
  }
}

.tab-sliding {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
}

.tab-sliding .tab-pane.active {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  visibility: visible;
  max-height: 100%;
  transition-delay: 0s;
  background-color: white;
}

.tab-sliding .tab-pane.active~.tab-pane {
  margin-left: 100%;
  margin-right: -200%;
}

.tab-sliding .tab-pane {
  margin-left: -100%;
}

.tab-sliding .tab-pane {
  max-width: 100%;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: block !important;
  -webkit-transform: none;
  transform: none;
  visibility: hidden;
  max-height: 0;
  transition: margin-left, margin-right, max-height, visibility, -webkit-transform;
  transition: margin-left, margin-right, transform, max-height, visibility;
  transition: margin-left, margin-right, transform, max-height, visibility, -webkit-transform;
  transition-duration: 350ms, 350ms, 350ms, 350ms, 0s;
  transition-delay: 0s, 0s, 0s, 0s, 340ms;
}

.tab-sliding .tab-pane:not(.active) {
  pointer-events: none;
}

.card-block {
  padding: 1em;
}

.fa {
  margin: auto;
  padding-right: 5px;
  padding-left: 5px;
}

.dropzone .dz-message .dz-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.main-lineyellow {
  background-color: #F3B81F;
  height: 4px;
  border: none;
  margin-top: 0px;
}

.al-footer {
  padding: 0px !important;
}

.main-footer {
  color: white;
  font-family: "Century Gothic";
  font-weight: lighter;
  font-size: 10px;
}

.social-footer a {
  color: white;
}

.social-footer em {
  padding: 4px;
  border-radius: 50%;
  background-color: white;
  color: rgb(30, 72, 124);
  font-size: 12px;
  margin: 3px;
}

.vertical-hr {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: inherit;
  width: 1px;
  margin: 0px;
  margin-left: 1em;
  margin-right: 1em;
}

.main-footer-content {
  display: flex;
  margin: auto;
  justify-content: center;
}

.bottom-footer {
  display: flex;
  justify-content: space-between;
  padding: 3px;
  padding-bottom: 5px;
  font-size: 10px;
}

.bottom-footer a {
  cursor: pointer;
}

.head-policy {
  padding: 1em;
}

.corner-logo {
  object-fit: scale-down;
  width: 30%;
}

.policy-content {
  padding: 1em;
}

.policy-content,
.policy-content p {
  color: rgb(30, 72, 124);
  font-family: Arial, Helvetica, sans-serif;
}

.policy-title {
  text-align: center;
  padding: 1.5em;
}

.body-policy {
  padding: 1.5em;
}

.modal {

  .modal-content {
    color: #7d7d7d;

    .modal-header {
      background-color: #004E83;
      color: white;
      border: none;
    }

    .modal-footer {
      border: none;
    }

    .close {
      outline: none;
    }
  }
}

@media (max-width: 590px) {
  .vertical-hr {
    display: none;
  }

  .main-footer-content {
    flex-direction: column;
    text-align: center;
  }

  .bottom-footer {
    font-size: 8px;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 30px !important;
}

select::-ms-expand {
  display: none;
}

.content-select {
  display: flex;
  position: relative;
}

.content-select::after {
  content: "\025be";
  display: flex;
  padding-top: 7px;
  text-align: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0px;
  transform: translateX(40%);
  pointer-events: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 750px;
    width: 90%;
  }
}

.modal-dialog .modal-body {
  max-height: 78vh;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 110px;
}

.modal-dialog .container {
  width: 90% !important;
}

.required-input {
  color: red;
}

.disabled-input[readonly] {
  color: darkgray;
}
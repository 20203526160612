$hover: 24;

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
}

.btn {
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.1s ease;
  padding: 0.344rem 1rem;
  font-size: 0.9rem;
  margin: 3px;
}

.btn:hover {
  transform: scale(1.2);
}

input[type="radio"],
input[type="checkbox"] {
  margin: 3px;
}

#btnGroupDrop1 {
  min-width: 140px;
  margin-left: 20px;
  margin-right: 16px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

#btnGroupDrop1 i {
  padding-right: 5px;
}

@mixin styleButton($color, $borderColor) {
  background: $color;
  border-color: $borderColor;
}

@mixin buttonColor($color, $borderColor) {
  @include styleButton($color, $borderColor);

  &.disabled,
  &[disabled],
  fieldset[disabled] &,
  &.disabled:hover,
  &[disabled]:hover,
  fieldset[disabled] &:hover,
  &.disabled:focus,
  &[disabled]:focus,
  fieldset[disabled] &:focus,
  &.disabled.focus,
  &[disabled].focus,
  fieldset[disabled] &.focus,
  &.disabled:active,
  &[disabled]:active,
  fieldset[disabled] &:active,
  &.disabled.active,
  &[disabled].active,
  fieldset[disabled] &.active {
    $mixHover: mix($color, $borderColor, calc($hover / 2)); // Aquí se ajusta el peso
    @include styleButton($color, $mixHover);

    &:hover {
      transform: none;
    }
  }

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    $mixHover: mix($color, $borderColor, $hover); // Aquí se ajusta el peso
    @include styleButton($color, $mixHover);
  }
}


@mixin openDropdownButton($color, $dark-color) {
  $mixHover: mix($color, $dark-color, calc($hover / 100));
  @include styleButton($color, $mixHover);
  background-color: $dark-color;
  border-color: $dark-color;
}

.open>.btn.dropdown-toggle {
  &.btn.btn-primary {
    @include openDropdownButton($primary, $primary-dark)
  }

  &.btn-success {
    @include openDropdownButton($success, $success-dark)
  }

  &.btn-info {
    @include openDropdownButton($info, $info-dark)
  }

  &.btn-warning {
    @include openDropdownButton($warning, $warning-dark)
  }

  &.btn-danger {
    @include openDropdownButton($danger, $danger-dark)
  }
}

button.btn.btn-primary {
  @include buttonColor($primary, $primary);

  &:active,
  &:target {
    background-color: $primary-dark;
  }
}

button.btn.btn-default {
  border-width: 1px;
  color: $content-text;
  @include buttonColor(transparent, $border);

  &:active,
  &:target {
    background-color: rgba(0, 0, 0, 0.2);
    color: $default-text;
  }
}

button.btn.btn-success {
  @include buttonColor($success, $success);

  &:active,
  &:target {
    background-color: $success-dark;
  }
}

button.btn.btn-info {
  @include buttonColor($info, $info);

  &:active,
  &:target {
    background-color: $info-dark;
  }
}

button.btn.btn-warning {
  @include buttonColor($warning, $warning);

  &:active,
  &:target {
    background-color: $warning-dark;
  }
}

button.btn.btn-danger {
  @include buttonColor($danger, $danger);

  &:active,
  &:target {
    background-color: $danger-dark;
  }
}

button.btn.btn-inverse {
  @include buttonColor($help-text, $help-text);
  color: $label-text;

  &:active,
  &:target,
  &:hover {
    background-color: $help-text;
    color: $label-text;
  }
}

.btn-with-icon {
  i {
    margin-right: 10px;
  }
}

.btn-group,
.btn-toolbar {
  :hover {
    transform: none;
  }
}

@mixin buttonGroupColor($color) {
  $intermediateColor: lighten($color, calc($hover / 2));
  border-color: mix($color, $intermediateColor);

  &:hover {
    $mediateColor: lighten($color, calc($hover / 2));
    border-color: mix($color, $mediateColor);
  }
}

.btn-group {
  button.btn.btn-primary {
    @include buttonGroupColor($primary);
  }

  button.btn.btn-danger {
    @include buttonGroupColor($danger);
  }

  button.btn.btn-info {
    @include buttonGroupColor($info);
  }

  button.btn.btn-success {
    @include buttonGroupColor($success);
  }

  button.btn.btn-warning {
    @include buttonGroupColor($warning);
  }

  .dropdown-menu {
    margin-top: 0px;
  }
}

.btn-toolbar {
  display: inline-block;
}

.btn .caret {
  margin-left: 2px;
}

@mixin progressButtonColor($btnColor) {
  border-radius: 0;

  .content {

    &:after,
    &:before {
      color: darken($btnColor, 40);
    }
  }

  &.progress-button-style-move-up,
  &.progress-button-style-slide-down {
    .content {
      background-color: darken($btnColor, 10);
    }
  }

  &.progress-button-style-lateral-lines .progress-inner {
    border-color: darken($btnColor, 10);
    background: 0 0;
  }

  .progress {
    background-color: darken($btnColor, 10);
    box-shadow: 0 1px 0 darken($btnColor, 10);
  }

  .progress-inner {
    background-color: darken($btnColor, 20);
  }

  &.progress-button-perspective {
    background: none;

    .content {
      background-color: $btnColor;
    }
  }
}

button.progress-button {

  .progress {
    margin-bottom: 0;
    border-radius: 0;
  }

  &:hover {
    transform: none;
  }

  &.progress-button-style-shrink.btn.disabled.progress-button-dir-horizontal:hover {
    transform: scaleY(.3);
  }

  &.progress-button-style-shrink.btn.disabled.progress-button-dir-vertical:hover {
    transform: scaleX(.1);
  }

  &.btn.btn-primary {
    @include progressButtonColor($primary);
  }

  &.btn.btn-default {
    @include progressButtonColor($default);
  }

  &.btn.btn-success {
    @include progressButtonColor($success);
  }

  &.btn.btn-info {
    @include progressButtonColor($info);
  }

  &.btn.btn-warning {
    @include progressButtonColor($warning);
  }

  &.btn.btn-danger {
    @include progressButtonColor($danger);
  }
}

.btn-raised {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.35);
  min-width: 140px;
}

.btn-mm {
  padding: 5px 11px;
  font-size: 13px;
}

.btn-xm {
  padding: 8px 14px;
  font-size: 16px;
}

.btn-group-xs>.btn,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 0px;
}

.dropdown button.btn.btn-default.dropdown-toggle {
  color: $default-text;
  border: 1px solid $border;
  background-color: transparent;

  &:focus,
  &:active {
    background-color: $default;
  }
}

.ng2,
.blur {
  .dropdown button.btn.btn-default.dropdown-toggle {

    &:focus,
    &:active {
      background-color: transparent;
    }
  }
}

.bootstrap-select {
  .dropdown-toggle:focus {
    outline: none !important;
  }

  button.btn-default:focus {
    color: $default;
  }

  .btn {
    transition: none;
  }
}